const USER_TOKEN = 'userToken';
const USER_TOKEN_EXPIRATION = 'userTokenExpiration';
const USER_NAME = 'userName';
const USER_ID = 'userId'
const USER_FULLNAME = 'userFullName'
const USER_STORE = 'userStore';
const USER_IDSTORE = 'userIdStore';
const USER_PROFILE = 'userProfile'
const USER_IDPROFILE = 'userIdProfile'

export const signInCredentials = (userName, tokenInformation) => {

    window.localStorage.setItem(USER_TOKEN_EXPIRATION,
        tokenInformation.userTokenExpiration);

    window.localStorage.setItem(USER_TOKEN,
        tokenInformation.accessToken);

    window.localStorage.setItem(USER_NAME,
        userName);
};

export const signOutCredentials = () => {
    window.localStorage.clear();
    window.localStorage.removeItem("RESTAURANTES");
    window.localStorage.removeItem(USER_TOKEN);
    window.localStorage.removeItem(USER_TOKEN_EXPIRATION);
    window.localStorage.removeItem(USER_NAME);    
    window.localStorage.removeItem(USER_ID);
    window.localStorage.removeItem(USER_STORE);
    window.localStorage.removeItem(USER_FULLNAME);
    window.localStorage.removeItem(USER_PROFILE);
    window.localStorage.removeItem(USER_IDPROFILE);
    window.localStorage.removeItem(USER_IDSTORE);
};

export const redirectToAdminPage = () => {
    window.location.href = '/app/idtorders/orders';
};

export const redirectToLoginPage = () => {
    window.location.href = '/';
};

export const getUserToken = () => {
    return localStorage.getItem(USER_TOKEN);
};

export const getUserTokenExpiration = () => {
    return localStorage.getItem(USER_TOKEN_EXPIRATION);
};

export const getUserName = () => {
    return localStorage.getItem(USER_NAME);
};

export const getUserId = () => {
    return localStorage.getItem(USER_ID);
};

export const getUserIdStore = () => {
    return localStorage.getItem(USER_IDSTORE);
};

export const getUserStore = () => {
    return localStorage.getItem(USER_STORE);
};

export const getUserFullName = () => {
    return localStorage.getItem(USER_FULLNAME);
};

export const getUserIdProfile = () => {
    return localStorage.getItem(USER_IDPROFILE);
};

export const getUserProfile = () => {
    return localStorage.getItem(USER_PROFILE);
};

export const isAuthenticated = () => {
    var userToken = getUserToken();
    var userTokenExpiration = getUserTokenExpiration();

    var dataAtual = new Date();
    var dataDeExpiracaoDoToken = new Date(userTokenExpiration);

    return (userToken && dataDeExpiracaoDoToken > dataAtual)
}

export const setUserTokenInfos = (user) => {
    window.localStorage.setItem(USER_ID,
        user.id);
    window.localStorage.setItem(USER_STORE,
        user.filial);
    window.localStorage.setItem(USER_FULLNAME,
        user.nome);
    window.localStorage.setItem(USER_PROFILE,
        user.perfil);
    window.localStorage.setItem(USER_IDPROFILE,
        user.idPerfil);
    window.localStorage.setItem(USER_IDSTORE,
        user.idFilial);
}

